.contactForm__wrapper {
    justify-content: center;
    display: flex;
    align-items: center;
    height: 100vh;
}

.contactForm {
    background-color: white;
    width: min-content;
    padding: 40px;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
}

.contactIllustration_pane {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactIllustration_pane > img {
    height: 350px;
    margin-right: 60px;
}

.contactform_pane > h1 {
    margin-bottom: 25px;
    font-weight: 400;
    font-size: 24px;
}

textarea {
    padding: 13px;
    border: 2px solid #ededed;
    border-radius: 10px;
    outline: none;
    font-family: Poppins;
    background-color: white;
    margin: 10px;
    width: 350px;
}

.contactform_pane > button {
    padding: 10px;
    border: 2px solid #E8B894;
    border-radius: 4px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: Poppins;
    cursor: pointer;
    font-size: 14px;
    color: #E8B894;
    margin: 20px;
    background-color: transparent;
}

.contactform_pane > button:hover {
    background-color: #E8B894;
    color: white;
}

.epair_2 {
    display: flex;
    flex-direction: column;
}

.epair_2 > input {
    padding: 13px;
    border: 2px solid #ededed;
    border-radius: 10px;
    outline: none;
    font-family: Poppins;
    background-color: white;
    margin: 10px;
    width: 350px;
}

@media only screen and (max-width: 999px) {
    .contactIllustration_pane {
        display: none;
    }

    .contactForm {
        margin-top: 80px;
    }
}

@media only screen and (max-width: 495px) {
    .contactForm {
        padding: 20px;
    }
    
    .epair_2 > input {
        width: 250px;
    }
}