.navbar {
    display: flex;
    height: 100px;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99999;
}

.navbar__left >a> img {
    height: 120px;
    width: auto;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 50px;
    cursor: pointer;
}

.navbar__right {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.navbar__right {
    margin-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.navbar__right > a {
    text-decoration: none;
}

.navbar__right > a > p {
    margin: 20px;
    cursor: pointer;
    color: black;
}

.navbar__middle> a {
    text-decoration: none;
}

.navbar__middle> a > p {
    margin: 10px;
    cursor: pointer;
    color: black;
    font-size: 15px;
}

.navbar__middle {
    border: 1px solid black;
    border-radius: 25px;
    padding-right: 20px;
    padding-left: 20px;
    position: fixed;
    left: 42%;
    display: flex;
    flex-direction: row;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-left: -50px;
    transition: background-color 0.2s ease-in-out;
}

.blackbutton {
    color: white;
    border: none;
    outline: none;
    background-color: black;
    border-radius: 50px;
    font-family: Poppins;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;
    cursor: pointer;
    height: 45px;
}

.navbar__right_toggle {
    margin-right: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: none;
    margin-top: 30px;
}

.navbar__right_toggle > button {
    background-color: black;
    padding: 20px;
    padding-top: 17px;
    border-radius: 50%;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items:center;
    cursor: pointer;
}

.navbar__right_toggle > button > span {
    color: white;
    font-size: 20px;
}

@media only screen and (max-width: 850px) {
    .navbar__middle {
        display: none;
    }   

    .navbar__right {
        display: none;
    }

    .navbar__right_toggle {
        display: flex;
    }
}

.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0, 0.9);
    overflow-x: hidden;
    transition: 0.5s;
  }
  
  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 25px;
    cursor: pointer;
    color: #818181;
    display: block;
    transition: 0.3s;
    font-family: Poppins;
  }
  
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
  }

  #link1, #link2, #link3, #link4 {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
  
  #link1:hover, #link2:hover, #link3:hover, #link4:hover {
    opacity: 0.7;
  }