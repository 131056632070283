.home {
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column; 
    display: flex; 
    width: 100%;
}

.hero {
    height: 100vh;
    width: 95%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    display: flex;
    align-items: center;
    -webkit-user-select: none;
    background-image: radial-gradient(140% 107.13% at 50% 10%, transparent 44%, #ffd0ae89 75.27%, #ffd0ae 100%);
    border-radius: 30px;
    margin-bottom: 60px;
}

.home__wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home > .hero > h1 {
    text-align: center;
    font-size: 45px;
}

.home > .hero > h3 {
    font-weight: 400;
    margin: 10px;
    text-align: center;
}

.home > .hero > p {
    font-weight: 300;
    width: 60%;
    text-align: center;
    margin-top: 5px;
}

.highlight {
    background: linear-gradient(180deg,rgba(255,255,255,0) 50%, #FFD0AE 50%);
}

.button {
    padding: 10px;
    border: 2px solid #E8B894;
    border-radius: 4px;
    padding-left: 40px;
    padding-right: 40px;
    font-family: Poppins;
    cursor: pointer;
    font-size: 14px;
    color: #E8B894;
    margin: 20px;
    background-color: transparent;
    font-weight: 500;
}

.button:hover {
    background-color: #E8B894;
    color: white;
}

@media only screen and (max-width: 1000px) {
    .home > .hero > h1 {
        font-size: 40px;
    }

    .uniqueniche {
      flex-direction: column;
      padding-left: 0px !important;
    }
}

@media only screen and (max-width: 650px) {
    .home > .hero > h1 {
        font-size: 35px;
    }
}

@media only screen and (max-width: 550px) {
    .home > .hero > p {
        width: 100%;
    }

    .newsletter {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .removemedia {
        display: none;
    }
}

.uniqueniche_image > img {
    height: 550px;
}

.uniqueniche {
    display: flex;
    padding-left: 50px;
  }
  
  .uniqueniche_text {
    flex: 1;
  }
  
  .uniqueniche_image {
    flex: 1;
  }

  .uniqueniche_image > img {
    max-width: 100%;
    height: auto;
  }
  
.uniqueniche_text > p {
    margin: 20px;
    margin-left: 0;
}
  
.collab_schools {
    display: flex;
    flex-direction: column;
}

.collab_schools > h1{ 
    text-align: center;
    margin-bottom: 20px;
}

.collab_schools {
    margin-bottom: 80px;
}

.collab_schools > img {
    /* filter: grayscale(100%); */
  opacity: 1;
  contrast: 1.2;
  height: 100px;
}

footer {
    background: linear-gradient(rgba(255, 208, 174, 0), rgba(255, 208, 174, 1));    
    color: #333;
    padding: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .copyright-line {
    font-size: 14px;
     text-align: center;
  }

  .footer-row > img {
    height: 125px;
    margin-right: 20px;
  }
  
  .footer-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  .footer-row > a {
    margin-right: 20px;
    text-decoration: underline;
    color: black;
    cursor: pointer;
  }

  .successmessage {
    color: white;
    margin: 15px;
    margin-left: -10px;
    display: none;
    text-align: center;
  }

  .newsletter {
    background-image: url('https://images.unsplash.com/photo-1458560871784-56d23406c091?q=80&w=3174&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');

      /* Background size 'cover' scales the image to cover the entire container */
      background-size: cover;

      /* Background position 'center bottom' positions the image at the bottom center */
      background-position: center 70%;

    padding: 30px;
    padding-top: 60px;
    padding-bottom: 60px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 50px;
  }

  .newsletter > h1 {
    text-align: center;
    color: white
  }

  .newsletter > p {
    text-align: center;
    font-size: 16px;
    width: 80%;
    margin-bottom: 30px;
    color: white;
  }

  .newsletter_form > input {
    border: 1px solid rgba(0, 0, 0, 0.282);
    border-radius: 20px;
    outline: none;
    padding: 10px;
    margin-right: 10px;
    font-size: 14px;
    width: 250px;
  }

  .newsletter_form > button {
    background-color: black;
    border-radius: 50%;
    border: none;
    outline: none;
    padding: 10px;
    cursor: pointer;
  }

  .newsletter_form > button > img {
    height: 20px;
  }

  .newsletter_form {
    display: flex;
    justify-content: center;
    align-items: center;
  }