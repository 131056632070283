.teamCards {
    display: flex;
    flex-wrap: wrap;
}

.teamCard {
    display: flex;
    flex-direction: row;
}

.teamCard__details {
    display: flex;
    flex-direction: column;
}